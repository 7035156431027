export const filterOptions = {
  search: '',
  sort_by: {
    label: 'Sort by',
    options: [
      ['trending', 'Trending'],
      ['newest', 'Newest'],
      ['most_popular', 'Most Popular']
    ]
  },
  assetType: {
    label: 'Asset Type',
    options: [
      ['real', 'Real'],
      ['cg', 'CG']
    ]
  },
  ownership: {
    label: 'Ownership',
    options: [
      ['not_owned', 'Not Owned'],
      ['partially_owned', 'Partially Owned'],
      ['owned', 'Owned']
    ]
  },
  frameRate: {
    label: 'Frame Rate',
    options: [
      ['24fps', '24fps'],
      ['30fps', '30fps'],
      ['60fps', '60fps'],
      ['90fps', '90fps'],
      ['120fps', '120fps & above']
    ]
  },
  minResolution: {
    label: 'Minimum Resolution',
    options: [
      ['HD', 'HD'],
      ['2K', '2K'],
      ['4K', '4K'],
      ['5K', '5K'],
      ['6K+', '6K+']
    ]
  },
  freeProducts: {
    label: 'Free Products',
    options: [
      ['free_for_everyone', 'Free for everyone'],
      ['free_for_subscribers', 'Free with AVFX+']
    ]
  }
};
