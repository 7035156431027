import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from '../types/state/storeTypes';

const defaultEndpoint: string = '/collections/ownership';

export default function getOwnerships(ownershipUrl: string = defaultEndpoint) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const [ownerships, setOwnerships] = useState(null);

  const dispatch = useDispatch();
  const state: ApplicationState = useSelector((state) => state) as ApplicationState;
  const token = state?.auth?.user?.token;

  useEffect(() => {
    if (!token || loading) return;

    const fetchOwnerships = async () => {
      setLoading(true);
      dispatch({
        type: '',
        api: {
          method: 'GET',
          url: ownershipUrl,
          headers: { authorization: token },
          onError: (res: any) => {
            setLoading(false);
            setError(res);
            return false;
          },
          onSuccess: (res) => {
            setOwnerships(res.data.data);
            setLoading(false);
          }
        }
      });
    };

    fetchOwnerships();
  }, [token]);

  return { loading, ownerships, error };
}
