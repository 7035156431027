import React, { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import { ProductFiltersProps } from '../../types/pages/products/CollectionsTypes';
import { filterOptions } from '../../staticData/ProductFilters';
import {
  CheckboxCheckedIcon,
  CheckboxEmptyIcon,
  CheckmarkIcon,
  DownChevronIcon,
  SearchIcon,
  SmallExitIcon
} from '../../modules/Icons';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { ENV } from '../../constants/environments';
import { useRouter } from 'next/router';
import debounce from 'lodash.debounce';
import { highlightKeywords } from '../../helpers/shared';
import { getCollectSearchResultsPluginPropsFor } from '../../helpers/analytics/userInterestAnalytics';
import TagContainer from '../shared/TagContainer';
import { Dialog, Menu } from '@headlessui/react';
import { MainLinkButton } from '../styled/buttons/MainLinkButton';
import errorRedirectHandler from '../../helpers/errorRedirectHandler';
import SendSearchAnalyticsPlugin from '../user-interest-analytics/SendSearchAnalyticsPlugin';
import CollectSearchResultsPlugin from '../user-interest-analytics/CollectSearchResultsPlugin';
import { ProductSearchResultType } from '../../types/api/ProductsIndexTypes';

const DEBOUNCE_MS = 750;

export const ProductFilters: React.FC<ProductFiltersProps> = (props) => {
  const {
    defaults,
    popularCategories,
    category,
    showAvfxTags,
    shallowCategoryLinks = false,
    shallowRoutingOnSearch = false,
  } = props;

  const [searchResults, setSearchResults] = useState({});
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const router = useRouter();
  const apiUrl: string = `${ENV.api.baseURL}`;
  const allSelected =
    defaults['membership_tier[silver]'] == '1' &&
    defaults['membership_tier[elite]'] == '2' &&
    defaults['membership_tier[gold]'] == '3';

  const noneSelected =
    defaults['membership_tier[silver]'] == undefined &&
    defaults['membership_tier[elite]'] == undefined &&
    defaults['membership_tier[gold]'] == undefined;

  const onlyAll = allSelected || noneSelected;

  const placeholder = router.asPath.includes('/contributors')
    ? 'Search this contributor'
    : 'Search 11,000+ assets'

  const defaultValues = {
    sort_by: defaults.sort_by || 'trending',
    assetType: defaults.assetType || [],
    ownership: defaults.ownership || [],
    price: defaults.price || [],
    frameRate: defaults.frameRate || [],
    minResolution: defaults.minResolution || null,
    freeProducts: defaults.freeProducts || null,
    search: defaults.search || '',
    tags: defaults.tags || {
      all: onlyAll,
      silver: onlyAll ? false : defaults['membership_tier[silver]'] == '1' || false,
      gold: onlyAll ? false : defaults['membership_tier[gold]'] == '2' || false,
      elite: onlyAll ? false : defaults['membership_tier[elite]'] == '3' || false
    }
  };

  const { register, getValues, setValue, watch } = useForm({ defaultValues });
  const searchTermRef = useRef<string>('');

  const handleChange = (f) => {
    if (f.target.getAttribute('id') == 'searchField') {
      return false;
    }
    handleSortBy(mapFieldsToParams());
  };

  const mapFieldsToParams = () => {
    const tags: any = getValues('tags');
    return {
      ...defaults,
      sort_by: getValues('sort_by'),
      'membership_tier[silver]': tags.all || tags.silver ? 1 : undefined,
      'membership_tier[gold]': tags.all || tags.gold ? 2 : undefined,
      'membership_tier[elite]': tags.all || tags.elite ? 3 : undefined
      // search: getValues('search')
    };
  };

  const handleSortBy = (params) => {
    const uri = router.pathname;
    const url = uri + '?' + new URLSearchParams(params).toString();
    router.push(url, undefined, { shallow: shallowRoutingOnSearch });
  };

  const handleSearch = debounce(async () => {
    if (getValues('search') == '') {
      setSearchResults({});
      setShowSearchResults(false);
      return;
    }

    const url = `${apiUrl}/searches?cq=${getValues('search')}&from_searchbox=true`;
    const res = await axios.get(url);
    // use search term ref to sync search term and results tracking
    searchTermRef.current = getValues('search');
    setSearchResults(res.data);
    setShowSearchResults(true);
  }, DEBOUNCE_MS);

  const resetSearchValue = () => {
    const inputElement = document.getElementById('searchField') as HTMLInputElement;
    if (inputElement !== null) {
      inputElement.value = '';
    }
  };

  useEffect(() => {
    resetSearchValue();
    setInputValue(getValues('search'));
  }, [showSearchResults === true]);

  const resultCount = (): any => {
    return Object.values(searchResults).reduce((acc, val: any) => acc + val.length, 0);
  };

  const resultUrl = (result: any): string => {
    switch (result.type) {
      case 'Collection':
        return `/collections/${result.path}`;
      case 'Category':
        return `/collections/${result.path}/category`;
      case 'Keyword':
        return `/products/${result.path}`;
    }
  };

  useEffect(() => {
    // follow query params in url and reset search if fields changed
    const queryFields: Array<string> = ['search', 'sort_by'];
    if (queryFields.some((field: string) => !!router.query[field])) return;
    setValue('search', '');
    setValue('sort_by', 'trending');
  }, [router.query]);

  return (
    <form key="product-searching" onChange={handleChange} data-testid="productSearchingForm">
      <div className="pb-8">
        <div className="items-center w-full gap-8 lg:flex">
          <div className="relative w-full">
            <div className={``} data-testid="productSearchingContainer">
              <div className="rounded-[5px] flex items-center px-4 py-3 bg-a-dark-gray md:min-w-[400px]">
                <SearchIcon className="inline w-4 stroke-white" />
                {(category && (
                  <span
                    className="ml-3 mr-1 flex items-center text-[14px] text-white bg-a-blue hover:bg-blue-1000 px-2 py-1 whitespace-nowrap rounded-[5px] cursor-pointer"
                    onClick={() => router.push('/products')}
                  >
                    {category.name}
                    <SmallExitIcon className="inline h-4 ml-1 cursor-pointer stroke-white fill-white" />
                  </span>
                )) ||
                  (inputValue && (
                    <span
                      className="ml-3 mr-1 flex items-center text-[14px] text-white bg-a-blue hover:bg-blue-1000 px-2 py-1 whitespace-nowrap rounded-[5px] cursor-pointer capitalize"
                      onClick={() => {
                        window.location.href = '/products';
                      }}
                    >
                      {getValues('search')}
                      <SmallExitIcon className="inline h-4 ml-1 cursor-pointer stroke-white fill-white" />
                    </span>
                  ))}
                {!category && (
                  <input
                    className="w-full pl-2 text-sm bg-transparent outline-0"
                    {...register('search')}
                    id="searchField"
                    type="search"
                    placeholder={placeholder}
                    onKeyUp={handleSearch}
                    onFocus={() => setShowSearchResults(true)}
                  />
                )}
              </div>
                {showSearchResults && resultCount() > 0 && (
                <>
                  <div
                    className="fixed top-0 left-0 w-screen h-screen bg-black overlay bg-opacity-10"
                    onClick={() => setShowSearchResults(false)}
                  ></div>
                  <div
                    className="absolute left-0 right-0 results-container bg-a-dark-gray rounded-b-[5px] text-white z-50 pb-5 shadow-lg drop-shadow-md mt-[2px]"
                    key={searchTermRef.current}
                  >
                    <SendSearchAnalyticsPlugin
                      mode="productsPageDropdownSearch"
                      search_type='products-2d'
                      searchTerm={searchTermRef.current}
                    />
                    <div>
                      {Object.keys(searchResults).map((bucket) => (
                        <div key={bucket}>
                          {searchResults[bucket].length > 0 && (
                            <div className="uppercase px-5 pb-1 pt-4 text-[12px] text-gray-600">
                              {bucket == 'keywords' ? 'Search Suggestions' : bucket}
                            </div>
                          )}
                          {searchResults[bucket].map((result, k) => (
                            <Link
                              key={`${searchTermRef.current}-${k}`}
                              className="px-5 text-[16px] flex gap-3 py-2 items-center hover:bg-gray-800"
                              href={resultUrl(result)}
                              shallow={result.type === 'Category' && shallowCategoryLinks}
                            >
                              {result.thumbnail && (
                                <img
                                  src={result.thumbnail}
                                  className="w-16 border border-gray-800"
                                />
                              )}
                              {bucket == 'keywords' && (
                                <SearchIcon className="inline w-4 stroke-gray-600" />
                              )}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: highlightKeywords(getValues('search'), result.value)
                                }}
                              ></span>
                              {bucket !== ('keywords' as ProductSearchResultType) && (
                                <CollectSearchResultsPlugin
                                  {...getCollectSearchResultsPluginPropsFor(result, '2d', 'productsPageDropdownSearch')}
                                />
                              )}
                            </Link>
                          ))}
                        </div>
                      ))}
                      <p className="text-gray-600 px-5 pt-4 text-[14px] border-t border-gray-800 mt-4">
                        Click <span className="font-bold text-gray-500">Enter</span> for more search
                        results.
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <Dialog open={false} onClose={() => {}} className="relative z-50">
            <div
              className="fixed inset-0 flex items-center justify-center overflow-y-auto bg-black/60"
              onClick={(e) => {
                const target = e.target as Element;
                if (!target.classList.contains('filter-modal')) {
                }
              }}
            >
              <Dialog.Panel>
                <div className="filter-modal bg-gray-1000 shadow-lg drop-shadow-lg rounded-[5px] p-8 text-white w-[90%] md:w-[500px] lg:w-[640px]">
                  <h4 className="text-[28px] uppercase eurostile font-semibold">FILTERS</h4>

                  <div className="grid w-full my-4 md:grid-cols-2">
                    <div>
                      <div className="py-4">
                        <label className="eurostile uppercase text-[16px] block mb-2">
                          {filterOptions.assetType.label}
                        </label>
                        {filterOptions.assetType.options.map((opt, k) => (
                          <div
                            key={opt[0]}
                            className="flex items-center gap-3 py-1 text-gray-600 text-[16px] font-light"
                          >
                            <input
                              id={'assetType_' + k}
                              className="hidden form-checkbox"
                              type="checkbox"
                              value={opt[0]}
                              {...register('assetType')}
                            />
                            <label
                              className={`cursor-pointer text-[14px] flex gap-2 items-center py-1 ${
                                watch('assetType') == opt[0] ? 'text-a-blue font-normal' : ''
                              }`}
                              htmlFor={'assetType_' + k}
                            >
                              {watch('assetType').includes(opt[0]) ? (
                                <CheckboxCheckedIcon />
                              ) : (
                                <CheckboxEmptyIcon />
                              )}
                              {opt[1]}
                            </label>
                          </div>
                        ))}
                      </div>

                      <div className="py-4">
                        <label className="eurostile uppercase text-[16px] block mb-2">
                          {filterOptions.ownership.label}
                        </label>
                        {filterOptions.ownership.options.map((opt, k) => (
                          <div
                            key={opt[0]}
                            className="flex items-center gap-3 py-1 text-gray-600 text-[16px] font-light"
                          >
                            <input
                              id={'ownership_' + k}
                              className="hidden form-checkbox"
                              type="checkbox"
                              value={opt[0]}
                              {...register('ownership')}
                            />
                            <label
                              className={`cursor-pointer text-[14px] flex gap-2 items-center py-1 ${
                                watch('ownership') == opt[0] ? 'text-a-blue font-normal' : ''
                              }`}
                              htmlFor={'ownership_' + k}
                            >
                              {watch('ownership').includes(opt[0]) ? (
                                <CheckboxCheckedIcon />
                              ) : (
                                <CheckboxEmptyIcon />
                              )}
                              {opt[1]}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div>
                      <div className="py-4">
                        <label className="eurostile uppercase text-[16px] block mb-2">
                          {filterOptions.frameRate.label}
                        </label>
                        {filterOptions.frameRate.options.map((opt, k) => (
                          <div
                            key={opt[0]}
                            className="flex items-center gap-3 py-1 text-gray-600 text-[16px] font-light"
                          >
                            <input
                              id={'frameRate' + k}
                              className="hidden form-checkbox"
                              type="checkbox"
                              value={opt[0]}
                              {...register('frameRate')}
                            />
                            <label
                              className={`cursor-pointer text-[14px] flex gap-2 items-center py-1 ${
                                watch('frameRate') == opt[0] ? 'text-a-blue font-normal' : ''
                              }`}
                              htmlFor={'frameRate' + k}
                            >
                              {watch('frameRate').includes(opt[0]) ? (
                                <CheckboxCheckedIcon />
                              ) : (
                                <CheckboxEmptyIcon />
                              )}
                              {opt[1]}
                            </label>
                          </div>
                        ))}
                      </div>

                      <div className="py-4">
                        <label className="eurostile uppercase text-[16px] block mb-2">
                          {filterOptions.minResolution.label}
                        </label>
                        {filterOptions.minResolution.options.map((opt, k) => (
                          <div
                            key={opt[0]}
                            className="flex items-center gap-3 py-1 text-gray-600 text-[16px] font-light"
                          >
                            <input
                              id={'minResolution' + k}
                              className="hidden form-checkbox"
                              type="radio"
                              value={opt[0]}
                              {...register('minResolution')}
                            />
                            <label
                              className={`cursor-pointer text-[14px] flex gap-2 items-center py-1 ${
                                watch('minResolution') == opt[0] ? 'text-a-blue font-normal' : ''
                              }`}
                              htmlFor={'minResolution' + k}
                            >
                              {watch('minResolution') == opt[0] ? (
                                <CheckboxCheckedIcon />
                              ) : (
                                <CheckboxEmptyIcon />
                              )}
                              {opt[1]}
                            </label>
                          </div>
                        ))}
                      </div>

                      <MainLinkButton variant="default" inline={true} onClick={() => {}}>
                        Apply Filters
                      </MainLinkButton>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </Dialog>
          <div className="md:min-w-[220px] md:mt-4 lg:mt-0 text-right p-2 md:p-0">
            <label className="text-sm text-a-gray">{filterOptions.sort_by.label}</label>
            <Menu>
              <Menu.Button>
                <span className="relative ml-3">
                  {filterOptions.sort_by?.options.find((k) => k[0] == getValues('sort_by'))[1] ||
                    'Default'}{' '}
                  <DownChevronIcon className="inline ml-1.5 mb-1 scale-[1.2] stroke-white" />
                </span>
              </Menu.Button>
              <Menu.Items className="absolute bg-gray-1000 z-30 right-6 mt-4 rounded-[5px] shadow-md overflow-hidden">
                {filterOptions.sort_by.options.map((opt) => (
                  <Menu.Item key={opt[0]}>
                    <div
                      key={opt[0]}
                      className="text-left px-4 py-2 hover:bg-gray-800 cursor-pointer text-[14px]"
                      onClick={(e) => {
                        setValue('sort_by', opt[0]);
                        handleChange(e);
                      }}
                    >
                      {opt[1]}
                    </div>
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Menu>

            <select
              {...register('sort_by')}
              className="hidden ml-4 bg-gray-900 border-0 text-md outline-0"
            >
              {filterOptions.sort_by.options.map((opt) => (
                <option key={opt[0]} value={opt[0]} className="bg-gray-900">
                  {opt[1]}
                </option>
              ))}
            </select>
          </div>
        </div>
        {popularCategories &&
          router.asPath.includes('products') &&
          !router.asPath.includes('products?search') && (
            <div className="hidden mt-5 xl:block">
              <label className="text-[14px] text-[#858788]">Popular:</label>
              {popularCategories.map((c) => (
                <a
                  key={c.link}
                  className="px-4 py-2 bg-[rgb(255,255,255)] bg-opacity-10 hover:bg-opacity-[.15] mx-2 rounded-3xl text-[#AEB6BA] text-[14px] whitespace-nowrap"
                  href={`/collections/${c.link}/category`}
                >
                  <span>{c.name}</span>
                  {c.elements > 0 && (
                    <span className="ml-2 text-gray-600 text-[12px]">{c.elements}</span>
                  )}
                </a>
              ))}
            </div>
          )}

        {showAvfxTags && (
          <div className="flex gap-1 pt-6 pb-2 md:gap-3 md:gap-6">
            {['all', 'silver', 'gold', 'elite'].map((tier) => (
              <label key={tier} className="flex items-center gap-1 cursor-pointer">
                <input
                  className="hidden"
                  type="checkbox"
                  onClick={() => {
                    const values = getValues('tags') as any;
                    const apply = { [tier]: !values[tier] };
                    if (values.all) {
                      setValue('tags', { ...values, ...apply, all: false });
                    } else {
                      setValue('tags', { ...values, ...apply });
                    }
                    return true;
                  }}
                />
                <div
                  className={`flex items-center justify-center w-[16px] h-[16px] border border-gray-600`}
                >
                  {getValues(('tags.' + tier) as any) ? (
                    <CheckmarkIcon className="w-[12px]" />
                  ) : (
                    <></>
                  )}
                </div>
                {tier == 'all' ? 'All' : <TagContainer membershipTier={tier} />}
              </label>
            ))}
          </div>
        )}
      </div>
    </form>
  );
};
